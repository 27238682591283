import React from 'react';
import './Header.css';

export const Header = () => {
    return(
        <div className='header-wrapper'>
            <h1> Maya Yoga </h1>
            <h2> Horario </h2>
        </div>
    )
}
